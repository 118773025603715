import type { PublicCorporateLocales } from "@/modules/corporate/types/locales"
import type { FunctionComponent } from "react"

import { useTranslation } from "@/modules/i18n/components/useTranslation"
import { useLocale } from "@/modules/locales/useLocale"

import styles from "./FooterMenuList.module.css"

const URL_PATHNAME_PER_LOCALE: Record<PublicCorporateLocales, Record<string, string>> = {
  de: {
    jobteaser_for_companies: "corporate/recruiter",
    our_solutions: "corporate/das-wichtigste",
  },
  en: {
    jobteaser_for_companies: "corporate/recruiters",
    our_solutions: "corporate/the-essentials",
  },
  fr: {
    jobteaser_for_companies: "corporate/recruteurs",
    our_solutions: "corporate/les-essentiels",
  },
}

export const FooterMenuListCompanies: FunctionComponent = () => {
  const { t } = useTranslation(["module_footer_fo"])
  const locale = useLocale() as PublicCorporateLocales

  return (
    <>
      <p className={styles.title}>{t("module_footer_fo.companies")}</p>
      <ul className={styles.main}>
        {Object.entries(URL_PATHNAME_PER_LOCALE[locale] || URL_PATHNAME_PER_LOCALE.en).map(
          ([translationKey, pathname]) => (
            <li key={translationKey} className={styles.item}>
              <a className={styles.link} href={`/${URL_PATHNAME_PER_LOCALE[locale] ? locale : "en"}/${pathname}`}>
                {t(`module_footer_fo.${translationKey}`)}
              </a>
            </li>
          )
        )}
      </ul>
    </>
  )
}
