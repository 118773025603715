import type { FunctionComponent } from "react"

import classnames from "classnames"

import { FooterMenuListCompanies } from "./FooterMenuListCompanies"
import { FooterMenuListJobTeaser } from "./FooterMenuListJobTeaser"
import { FooterMenuListSchoolsUniversities } from "./FooterMenuListSchoolsUniversities"
import { FooterMenuListStudent } from "./FooterMenuListStudent"

import styles from "./FooterMenu.module.css"

export const FooterMenu: FunctionComponent = () => {
  const menuItemStyles = classnames(styles.menuItem, styles.menuItem__desktop)

  return (
    <div className={styles.main}>
      <div className={menuItemStyles}>
        <FooterMenuListStudent />
      </div>

      <div className={menuItemStyles}>
        <FooterMenuListSchoolsUniversities />
      </div>

      <div className={menuItemStyles}>
        <FooterMenuListCompanies />
      </div>

      <div className={styles.menuItem}>
        <FooterMenuListJobTeaser />
      </div>
    </div>
  )
}
