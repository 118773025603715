import type { PublicCorporateLocales } from "@/modules/corporate/types/locales"
import type { FunctionComponent } from "react"

import { useTranslation } from "@/modules/i18n/components/useTranslation"
import { useLocale } from "@/modules/locales/useLocale"

import styles from "./FooterMenuList.module.css"

const URL_PATHNAME_PER_LOCALE: Record<PublicCorporateLocales, Record<string, string>> = {
  de: {
    career_center_by_jobteaser: "corporate/career-services",
    our_network: "corporate/unser-hochschulnetzwerk",
  },
  en: {
    career_center_by_jobteaser: "corporate/career-services",
    our_network: "corporate/our-network-of-schools-and-universities",
  },
  fr: {
    career_center_by_jobteaser: "corporate/services-carrieres",
    our_network: "corporate/notre-reseau-d-ecoles-et-d-universites",
  },
}

export const FooterMenuListSchoolsUniversities: FunctionComponent = () => {
  const { t } = useTranslation(["module_footer_fo"])
  const locale = useLocale() as PublicCorporateLocales

  return (
    <>
      <p className={styles.title}>{t("module_footer_fo.schools_universities")}</p>
      <ul className={styles.main}>
        {Object.entries(URL_PATHNAME_PER_LOCALE[locale] || URL_PATHNAME_PER_LOCALE.en).map(
          ([translationKey, pathname]) => (
            <li key={translationKey} className={styles.item}>
              <a className={styles.link} href={`/${URL_PATHNAME_PER_LOCALE[locale] ? locale : "en"}/${pathname}`}>
                {t(`module_footer_fo.${translationKey}`)}
              </a>
            </li>
          )
        )}
      </ul>
    </>
  )
}
